import { render, staticRenderFns } from "./MySwiper.vue?vue&type=template&id=364da7b8&scoped=true"
import script from "./MySwiper.vue?vue&type=script&lang=js"
export * from "./MySwiper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364da7b8",
  null
  
)

export default component.exports